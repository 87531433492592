<template>
  <Panel type="sheet" :customClass="customClass">
    <template v-slot:headline>アクティビティ</template>
    <template v-slot:body>
      <div class="scrollX">
        <table class="listTable">
          <tbody>
            <tr v-for="activity in lastestActivities" :key="activity.index">
              <th class="pre-line">{{ endline(activity.action, 20) }}</th>
              <td class="listTable-fix">{{ formatDateAndTime(activity.createDate) }}</td>
              <td class="listTable-fix">{{ activity.ipAddress || '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul class="listGrid" v-if="isShowBtnMore">
        <li class="listGrid-item pos-end">
          <button class="btn btn-black" type="button" @click="showModal('activityModal')">詳細を見る</button>
        </li>
      </ul>
      <Modal @close="closeModal('activityModal')" v-if="activityModal">
        <template v-slot:headline>アクティビティ</template>
        <template v-slot:body>
          <div class="scrollX">
            <table class="listTable">
              <tbody>
                <tr v-for="activity in activities" :key="activity.index">
                  <th class="fw-nomal pre-line">{{ endline(activity.action, 20) }}</th>
                  <td class="listTable-fix">{{ formatDateAndTime(activity.createDate) }}</td>
                  <td class="listTable-fix">{{ activity.ipAddress || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-slot:footer>
          <ul class="listGrid justify-content-end">
            <li class="listGrid-item">
              <button class="btn btn-white" type="button" @click="closeModal('activityModal')">閉じる</button>
            </li>
          </ul>
        </template>
      </Modal>
    </template>
  </Panel>
</template>

<script>
import modal from '@/mixins/plugin/modal';
import { endline } from '@/helpers/formatData';
import Modal from '@/components/Modal.vue';
import { EventConstants } from '@/constants/event';
import { formatDateAndTime } from '@/helpers/formatData';

export default {
  data: function() {
    return {
      formatDateAndTime,
      endline,
      activityModal: false
    };
  },
  components: {
    Modal,
  },
  mixins: [modal],
  props: {
    activities: {
      type: Array,
      required: true,
      default: () => [],
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    lastestActivities() {
      return this.activities.slice(0, 3);
    },
    isShowBtnMore() {
      return this.activities?.length > EventConstants.ACTIVITIES_MAX_LENGTH;
    }
  }
};
</script>

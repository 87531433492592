import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {
      navs: [
        {
          id: '01',
          name: 'ユーザー管理',
          path: '/setting/user',
          routeName: 'SettingUser',
          includes: true,
        },
        {
          id: '02',
          name: 'イベント管理',
          path: '/setting/event',
          routeName: 'SettingEvent',
          includes: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      hasUsersRole: 'auth/hasUsersRole'
    }),
    pageNavs() {
      if (!this.hasUsersRole) return this.navs.slice(1);
      return this.navs;
    }
  },
};
